.second-fold {
  background: linear-gradient(180deg, #101114 -2.24%, #08090a 100%);
}

.second-fold-black-bg {
  background: radial-gradient(
    187.34% 187.31% at 55.97% -84.49%,
    #4e52b8 0%,
    #000805 56.68%
  );
  mix-blend-mode: hard-light;
}

.second-fold-separator {
  background: linear-gradient(180deg, #101114 25.05%, rgba(16, 17, 20, 0) 100%);
  mix-blend-mode: luminosity;
  filter: blur(30.75px);
  width: 100%;
  height: 149.887px;
}

.second-fold-slide-container {
  border-radius: 17px;
  border: 1px solid #29292e;
  background: linear-gradient(
    180deg,
    rgba(98, 106, 136, 0.1) 0%,
    rgba(46, 49, 73, 0.1) 100%
  );
  backdrop-filter: blur(12px);
}

.third-fold {
  background: linear-gradient(180deg, #1a1c21 -2.24%, #101114 100%);
}

.third-fold-separator {
  background: linear-gradient(180deg, #181b1f 25.05%, rgba(24, 27, 31, 0) 100%);
  mix-blend-mode: luminosity;
  filter: blur(18.100000381469727px);
}

.third-fold-separator-2 {
  background: linear-gradient(180deg, #101114 25.05%, rgba(16, 17, 20, 0) 100%);
  mix-blend-mode: overlay;
  filter: blur(30.75px);
}

.new-markets-glass {
  border-radius: 12px;
  border: 1px solid #29292e;
  background: linear-gradient(
    180deg,
    rgba(98, 106, 136, 0.1) 0%,
    rgba(46, 49, 73, 0.1) 100%
  );
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(12px);
  width: 546px;
  height: 521px;
  flex-shrink: 0;
}

.new-markets-glass-separator-1 {
  border-radius: 12px;
  background: linear-gradient(
    180deg,
    #181b1f 25.17%,
    rgba(24, 27, 31, 0) 66.84%
  );
  mix-blend-mode: luminosity;
  filter: blur(18.100000381469727px);
  width: 538.395px;
  height: 64px;
  transform: rotate(-90.232deg) translateX(-230px) translateY(-238px);
  flex-shrink: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.new-markets-glass-separator-2 {
  width: 559.025px;
  height: 64.215px;
  transform: rotate(89.768deg) translateX(230px) translateY(-248px);
  flex-shrink: 0;
  border-radius: 12px;
  background: linear-gradient(
    180deg,
    #181b1f 25.17%,
    rgba(24, 27, 31, 0) 66.84%
  );
  mix-blend-mode: luminosity;
  filter: blur(18.100000381469727px);
  position: absolute;
  top: 0;
  right: 0;
}

.perspective {
  perspective: 2000px;
}

.perspective div {
  transform: rotateX(25deg);
}

.linear-animation {
  animation-duration: 2.1s;
  animation-timing-function: ease-out;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-play-state: running;
  animation-name: linearTypeAnim;
  animation-timeline: auto;
  animation-range: normal;
  animation-delay: 700ms;
}

.separator {
  background: linear-gradient(180deg, #181b1f 25.05%, rgba(24, 27, 31, 0) 100%);
  mix-blend-mode: luminosity;
  filter: blur(18.100000381469727px);
}

.orbit {
  --size: 20rem;
  --speed: 120s;
}
.orbit .center-image {
  width: var(--size);
  z-index: 10;
}
.orbit .center-image img {
  position: absolute;
  transition: opacity 500ms;
}
.orbit .center-image img:not(:first-child):hover {
  opacity: 0;
}
.orbit ul {
  display: grid;
  place-items: center;
  width: var(--size);
  height: var(--size);
  position: relative;
  list-style: none;
  --icon-bg: var(--green-light);
  --text-bg: var(--green-dark);
  transform-origin: center;
  animation: orbit var(--speed) linear infinite;
}
.orbit ul:hover {
  animation-play-state: paused;
  --orbit-play-state: paused;
}
.orbit li {
  --icon-bg: pink;
  --text-bg: blue;
  position: absolute;
  width: 8rem;
  aspect-ratio: 1/1;
  border-radius: 50%;
  font-weight: 500;
  color: var(--white-light);
  text-align: center;
  line-height: 1;
  display: grid;
  place-items: center;
}
.orbit li:hover {
  --throb-play-state: paused;
}
.orbit li:nth-child(1) {
  --throb-delay: 0ms;
}
.orbit li:nth-child(2) {
  --throb-delay: 500ms;
}
.orbit li:nth-child(3) {
  --throb-delay: 1000ms;
}
.orbit li:nth-child(4) {
  --throb-delay: 1500ms;
}
.orbit li:nth-child(5) {
  --throb-delay: 2000ms;
}
.orbit li:nth-child(6) {
  --throb-delay: 2500ms;
}
.orbit li:nth-child(7) {
  --throb-delay: 3000ms;
}
.orbit li:nth-child(8) {
  --throb-delay: 3500ms;
}
.orbit li > * {
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  border-radius: inherit;
  position: absolute;
  animation:
    orbit var(--speed) linear reverse infinite,
    throb 1s var(--throb-delay) ease-in-out infinite alternate;
  animation-play-state: var(--orbit-play-state, running),
    var(--throb-play-state, running);
}
.orbit li > div {
  background-color: var(--icon-bg);
}
.orbit li > p {
  background: var(--text-bg);
  transition: opacity 500ms;
  opacity: 1;
}

.orbit li:nth-child(1) {
  --icon-bg: var(--blue-light);
  --text-bg: var(--blue-dark);
  transform: translate(18rem, -7rem);
}
.orbit li:nth-child(2) {
  --icon-bg: var(--green-light);
  --text-bg: var(--green-dark);
  transform: translate(16rem, 10rem);
}
.orbit li:nth-child(3) {
  --icon-bg: var(--pink-light);
  --text-bg: var(--pink-dark);
  transform: translate(0, 20rem);
}
.orbit li:nth-child(4) {
  --icon-bg: var(--red-light);
  --text-bg: var(--red-dark);
  transform: translate(-14rem, 14rem);
}
.orbit li:nth-child(5) {
  --icon-bg: var(--brown-light);
  --text-bg: var(--brown-dark);
  transform: translate(-20rem, 0rem);
}
.orbit li:nth-child(6) {
  --icon-bg: var(--yellow-light);
  --text-bg: var(--yellow-dark);
  transform: translate(-11rem, -16rem);
}
.orbit li:nth-child(7) {
  --icon-bg: var(--purple-light);
  --text-bg: var(--purple-dark);
  transform: translate(5rem, -18rem);
}

@keyframes orbit {
  100% {
    rotate: 1turn;
  }
}
@keyframes hold-position {
  100% {
    rotate: -1turn;
  }
}
@keyframes throb {
  100% {
    scale: 1.05;
  }
}

.animation-infinite-ticker {
  width: max-content;
  white-space: nowrap;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: animate-infinite-ticker;
  animation-duration: 150s;
  cursor: default;
}
.animation-infinite-ticker:hover {
  animation-play-state: paused;
}

@keyframes animate-infinite-ticker {
  0% {
    transform: translateX(5%);
  }
  100% {
    transform: translateX(-95%);
  }
}

.glassmorphism {
  border-radius: 4px;
  background: linear-gradient(
    180deg,
    rgba(98, 106, 136, 0.1) 0%,
    rgba(46, 49, 73, 0.1) 100%
  );
  backdrop-filter: blur(6px);
}

.bg-noise {
  background-image: url("../public/images/bg-noise-small.png");
  background-repeat: repeat;
}

div::-webkit-scrollbar {
  display: none;
}

.slide1-bottom-left {
  position: absolute;
  z-index: 0;
  left: -6%;
  height: 428px;

  @media screen and (min-width: 1800px) {
    left: -3%;
  }
}

.absolute-center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.slide1-bottom-center {
  position: absolute;
  z-index: 10;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
  min-width: 50vw;
  width: 720px;
}

.slide1-bottom-right {
  position: absolute;
  z-index: 0;
  height: 409.6px;
  right: -6%;
  @media screen and (min-width: 1800px) {
    right: -3%;
  }
}

.card-list {
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
  bottom: 20px;
}
/* 
.card-list::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.card-list::-webkit-scrollbar-thumb {
  background: #201c29;
  border-radius: 10px;
  box-shadow: inset 2px 2px 2px hsla(0, 0%, 100%, 0.25),
    inset -2px -2px 2px rgba(0, 0, 0, 0.25);
}

.card-list::-webkit-scrollbar-track {
  background: linear-gradient(90deg, #201c29, #201c29 1px, #17141d 0, #17141d);
} */

.card {
  @apply w-[calc(100%-2rem)] xl:w-[846px] h-[240px] xl:h-[601px] 4xl:h-[747px];
  bottom: 40px;
  padding: 1.5rem;
  border-radius: 10px;
  /* box-shadow: 0 -1rem 3rem #000; */
  display: flex;
  flex-direction: column;
  margin: 0;
  scroll-snap-align: start;
  clear: both;
  position: absolute;
}

/* .card:not(:first-child) {
  margin-top: -140px;
} */

/* .card:nth-child(1) {
  transform: scale(0.7, 1);
}

.card:nth-child(2) {
  transform: scale(0.75, 1);
}

.card:nth-child(3) {
  transform: scale(0.8, 1);
}

.card:nth-child(4) {
  transform: scale(0.85, 1);
}

.card:nth-child(5) {
  transform: scale(0.9, 1);
}

.card:nth-child(6) {
  transform: scale(0.95, 1);
}

.card:nth-child(7) {
  transform: scale(1);
} */

.card-header:empty {
  margin-bottom: auto;
  background: rgb(224, 221, 221);
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
  height: 20px;
  width: 50%;
}

.card-header p:empty {
  font-size: 14px;
  margin: 0 0 1rem;
  background: #7a7a8c;
  width: 50%;
}

.card-header h2 {
  font-size: 20px;
  margin: 0.25rem 0 auto;
  text-decoration: none;
  color: inherit;
  border: 0;
  display: inline-block;
  cursor: pointer;
}

/* .card-header h2:hover {
  background: linear-gradient(90deg, #ff8a00, #e52e71);
  text-shadow: none;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
} */

/* .card-author {
  margin: 3rem 0 0;
  display: grid;
  grid-template-columns: 75px 1fr;
  align-items: center;
  position: relative;
}

.author-avatar {
  grid-area: auto;
  align-self: start;
  position: relative;
  box-sizing: border-box;
}

.author-avatar img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  filter: grayscale(100%);
  display: block;
  overflow: hidden;
  margin: 16px 10px;
}

.author-name {
  grid-area: auto;
  box-sizing: border-box;
}

.author-name-prefix {
  font-style: normal;
  font-weight: 700;
  color: #7a7a8c;
}

.half-circle {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 60px;
  height: 48px;
  fill: none;
  stroke: #ff8a00;
  stroke-width: 8;
  stroke-linecap: round;
  pointer-events: none;
}

.tags {
  margin: 1rem 0 2rem;
  padding: 0.5rem 0 1rem;
  line-height: 2;
  margin-bottom: 0;
}

.tags a {
  font-style: normal;
  font-weight: 700;
  font-size: 0.5rem;
  color: #7a7a8c;
  text-transform: uppercase;
  font-size: 0.66rem;
  border: 3px solid #28242f;
  border-radius: 2rem;
  padding: 0.2rem 0.85rem 0.25rem;
  position: relative;
}

.tags a:hover {
  background: linear-gradient(90deg, #ff8a00, #e52e71);
  text-shadow: none;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-box-decoration-break: clone;
  background-clip: text;
  border-color: white;
} */
