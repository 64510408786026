@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: the-alpha-day;
  src: url("./fonts/the-alpha-day/the-alpha-day.woff2") format("woff2");
  font-weight: 700;
}
@font-face {
  font-family: tt-commons-pro;
  src: url("./fonts/tt-commons-pro/TT_Commons_Pro_Thin.woff2") format("woff2");
  font-weight: 100;
}

@font-face {
  font-family: tt-commons-pro;
  src: url("./fonts/tt-commons-pro/TT_Commons_Pro_Light.woff2") format("woff2");
  font-weight: 300;
}

@font-face {
  font-family: tt-commons-pro;
  src: url("./fonts/tt-commons-pro/TT_Commons_Pro_Regular.woff2")
    format("woff2");
  font-weight: 400;
}

@font-face {
  font-family: tt-commons-pro;
  src: url("./fonts/tt-commons-pro/TT_Commons_Pro_Normal.woff2") format("woff2");
  font-weight: 400;
}

@font-face {
  font-family: tt-commons-pro;
  src: url("./fonts/tt-commons-pro/TT_Commons_Pro_Medium.woff2") format("woff2");
  font-weight: 500;
}

@font-face {
  font-family: tt-commons-pro;
  src: url("./fonts/tt-commons-pro/TT_Commons_Pro_DemiBold.woff2")
    format("woff2");
  font-weight: 600;
}

@font-face {
  font-family: tt-commons-pro;
  src: url("./fonts/tt-commons-pro/TT_Commons_Pro_Bold.woff2") format("woff2");
  font-weight: 700;
}
@font-face {
  font-family: tt-commons-pro;
  src: url("./fonts/tt-commons-pro/TT_Commons_Pro_ExtraBold.woff2")
    format("woff2");
  font-weight: 800;
}
@font-face {
  font-family: tt-commons-pro;
  src: url("./fonts/tt-commons-pro/TT_Commons_Pro_ExtraBlack.woff2")
    format("woff2");
  font-weight: 900;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #111113;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.hide-scroll::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  -webkit-appearance: none;
}

.hide-scroll::-webkit-scrollbar-thumb {
  border: 0px solid transparent;
  background-clip: padding-box;
}

.hide-scroll::-webkit-scrollbar-thumb:hover {
  border: 0;
}

.hide-scroll::-webkit-scrollbar-track {
  background: transparent;
}
