.container {
  position: relative;
  width: 100%;
  height: 529px;
}

@media (min-width: 1280px) {
  .container {
    width: 546px;
    height: 521px;
  }
}

.dark_bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 17px;
  border: 1px solid #29292e;
  opacity: 0.4;
  background-color: #09090a;
}

.glass {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  border: 1px solid #29292e;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  background: linear-gradient(
    180deg,
    rgba(98, 106, 136, 0.1) 0%,
    rgba(46, 49, 73, 0.1) 100%
  );
  backdrop-filter: blur(12px);
}

.bg_noise {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 18px;
  opacity: 0.8;
  background-image: url("../../public/images/bg-noise-small.png");
  background-repeat: repeat;
}

.vector {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  opacity: 0.5;
  background-image: url("../../public/images/vector-bg.png");
  background-size: cover;
}

.separator_first {
  border-radius: 12px;
  background: linear-gradient(
    180deg,
    #181b1f 25.17%,
    rgba(24, 27, 31, 0) 66.84%
  );
  mix-blend-mode: luminosity;
  filter: blur(18.100000381469727px);
  width: 538.395px;
  height: 64px;
  transform: rotate(-90.232deg) translateX(-230px) translateY(-238px);
  flex-shrink: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.separator_second {
  width: 559.025px;
  height: 64.215px;
  transform: rotate(89.768deg) translateX(230px) translateY(-248px);
  flex-shrink: 0;
  border-radius: 12px;
  background: linear-gradient(
    180deg,
    #181b1f 25.17%,
    rgba(24, 27, 31, 0) 66.84%
  );
  mix-blend-mode: luminosity;
  filter: blur(18.100000381469727px);
  position: absolute;
  top: 0;
  right: 0;
}
